@font-face {
  font-family: ProximaNovaRegular;
  src: url("./assets/Fonts/ProximaNova/ProximaNova-Regular.ttf");
}
html {
  overflow-x: hidden;
}
body {
  background-color: #16161a;
  margin: 0 auto;
  padding: 0;
  overflow-x: hidden;
}

.container {
  margin: 0 auto;
  max-width: 1110px;
  padding: 0 20px;
  /* background-color: #16161A; */
  color: #fff;
}
.road-map-container {
  margin: 0 auto;
  max-width: 900px;
  padding: 0 20px;
  color: #fff;
}

.rewardcontainer {
  margin: 0 auto;
  max-width: 1446px;
  color: #fff;
}

.appbarcolor {
  background-color: aqua;
}

.nav-container {
  margin: auto;
  max-width: 1110px;
  color: #fff;
  background-color: #16161a;
  display: flex;
  align-items: center;
  justify-content: space-between;
  min-height: 80px;
  padding: 0 20px;
}

.routerlink {
  text-decoration: none;
  color: #fff;
}

/* =========================================
Navbar hover effect
========================================= */

.dropdown-menu {
  display: none;
  z-index: 1;
}

.menu:hover .dropdown-menu {
  display: block;
}
